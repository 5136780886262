import { useContext, useEffect } from "react";
import { INSTAGRAM_URL, LINKEDIN_URL } from "../../mocks/externalUrls"
import { GlobalContext } from "../../context/globalContext";
import * as S from "./styles";
import handleResponsiveScroll from "../../mocks/handleResponsiveScroll";

import AdvantageIcon from "../../assets/icons/AdvantageIcon";

import MoodSrc from "../../assets/img/mood.png";
import DissatisfiedSrc from "../../assets/img/sentiment_dissatisfied.png";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import LinkedinIcon from "../../assets/icons/social/LinkedinIcon";


const OutcomeModal = (
    { rightFirstAnswer, rightSecondAnswer, rightThirdAnswer } : { rightFirstAnswer: boolean, rightSecondAnswer: boolean, rightThirdAnswer: boolean }
) => {
    const {
        resultInformations,
        setResultInformations,
        setShowIneligibleScreen,
        setMonthlyValue,
        setSelectedCity,
        setSelectedState,
        setShowSimulationResult,
    } = useContext(GlobalContext);

    function handleRedoSimulation() {
        setShowIneligibleScreen(false);
        setShowSimulationResult(false);
        setResultInformations(null);
        setSelectedCity(null);
        setMonthlyValue("");
        setSelectedState("");

        setTimeout(() => {
            handleResponsiveScroll(".economySimulation", { desk: -590, mob: -340 });
        }, 300);
    }

    useEffect(() => {
        handleResponsiveScroll(".outcomeModal", { desk: -500, mob: -150 });
    }, []);

    return (
        <S.OutcomeRoot>
            <S.OutcomeWrapper className="inelegibleSimulation">
                <S.InfoCol>
                    <S.DiscountHeader>
                        Pronto para economizar com a GUD Energia?
                    </S.DiscountHeader>

                    <S.DiscountValue>
                        {resultInformations?.percentage ?? "0"}% ao mês!
                    </S.DiscountValue>

                    <p>Em 5 anos, isso pode representar uma economia de:</p>

                    <S.DiscountValueIn3Years>
                        {resultInformations?.economy ?? "R$ 00,00"}
                    </S.DiscountValueIn3Years>

                    <S.DiscountAdvantageTextRow>
                        <AdvantageIcon />
                        <p>
                        Sem mudar seu padrão de consumo e sem necessidade de
                        investimento!
                        </p>
                    </S.DiscountAdvantageTextRow>

                    <S.SmallText>
                        Essa simulação de desconto possui efeitos indicativos e não deve
                        ser considerada como uma proposta comercial.{" "}
                        <strong>
                        Para saber o desconto exato, envie uma fatura recente de
                        energia elétrica e aguarde o contato de um consultor.
                        </strong>
                    </S.SmallText>
                </S.InfoCol>
                <S.OutcomeFormCol>
                    <S.OutcomeFormWrapper>
                        {!rightFirstAnswer ? (
                        <>
                            <h2>Neste momento nossa solução ainda não atende o seu perfil de consumo.</h2>

                            <p>Acompanhe a GUD nas redes sociais e <br></br> fique de olhos nas novidades.</p>

                            <div style={{ alignSelf: "center", marginTop: "1rem", display: "flex", flexDirection: "row" }}>
                                <S.SocialWrapper>
                                    <S.SocialLink target="_blank" href={INSTAGRAM_URL}>
                                        <InstagramIcon /> Instagram
                                    </S.SocialLink>
                                    <S.SocialLink target="_blank" href={LINKEDIN_URL}>
                                        <LinkedinIcon /> LinkedIn
                                    </S.SocialLink>
                                </S.SocialWrapper>
                            </div>

                            <S.RedoSimulationAlt>
                                <button onClick={() => handleRedoSimulation()} type="button">
                                    Simular novamente
                                </button>
                            </S.RedoSimulationAlt>
                        </>
                        ) : !rightSecondAnswer ? (
                        <>
                            <h2>Obrigado por enviar suas informações!</h2>

                            <p>Um representante da GUD irá entrar em <br></br> contato com você para entender a <br></br> situação do seu contrato atual.</p>

                            <div style={{ alignSelf: "center", width: "3.5rem", marginTop: "1rem" }}>
                                <img src={MoodSrc} alt="Mood" />
                            </div>

                            <S.RedoSimulationAlt>
                                <button onClick={() => handleRedoSimulation()} type="button">
                                    Simular novamente
                                </button>
                            </S.RedoSimulationAlt>
                        </>
                        ) : !rightThirdAnswer ? (
                        <>
                            <h2>Neste momento nossa solução não atende participantes do Mercado de Geração Distribuída</h2>

                            <p>Estamos trabalhando em uma solução <br></br> para este mercado e assim que disponível <br></br> entraremos em contato com você.</p>

                            <div style={{ alignSelf: "center", width: "3.5rem", marginTop: "1rem" }}>
                                <img src={DissatisfiedSrc} alt="Dissatisfied" />
                            </div>

                            <S.RedoSimulationAlt>
                                <button onClick={() => handleRedoSimulation()} type="button">
                                    Simular novamente
                                </button>
                            </S.RedoSimulationAlt>
                        </>
                        ) : (
                        <>
                            <div style={{ alignSelf: "center", width: "3.5rem", marginTop: "1rem", marginBottom: "-2rem" }}>
                                <img src={MoodSrc} alt="Mood" />
                            </div>
                            <h2>Obrigado por responder o questionário</h2>
                            <p>
                                <strong>Boas notícias!</strong> Pelas suas respostas você está elegível para migrar para o Mercado Livre de Energia. 
                            </p>
                            <p>
                                Em até 24 horas um representante GUD entrará em contato com uma proposta para você!
                            </p>
                        </>
                        )}
                    </S.OutcomeFormWrapper>
                </S.OutcomeFormCol>
            </S.OutcomeWrapper>
        </S.OutcomeRoot>
    );
};

export default OutcomeModal;
